var generic = generic || {};
var site = site || {};
var Drupal = Drupal || {};
var settings = Drupal.settings || {};
var FB = FB || '';

(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s); js.id = id;
  var locale = settings.fb_sdk_locale || 'en_US';
  js.src = '//connect.facebook.net/' + locale + '/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk')
);

(function($) {
  site.facebook = (function() {
    var nodes = {};

    var _getDOMNodes = function() {
      nodes.container = $('.social-login__container');
      nodes.form = $('#facebook_signin');
      nodes.appIdInput = nodes.form.find('input[name="appId"]');
      nodes.tokenInput = nodes.form.find('input[name="token"]');
      nodes.newAccountContainer = $('.js-new-account');
      nodes.errorContainer = nodes.newAccountContainer.find('.error_messages');
      nodes.divider = nodes.container.find('.social-login__divider');
      nodes.optInInput = $('.js-facebook_email_opt_in, input[type="checkbox"].js-confirm-email-promotions');
      nodes.fbDisconnect = $('#facebook_disconnect');
    };

    var _initFB = function(appId) {
      FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: 'v2.10'
      });
      if (!settings.fb_logpage_excluded) {
        FB.AppEvents.logPageView();
      }

      FB.Event.subscribe('xfbml.render', _showFbContainer);
    };

    if (typeof FB === undefined) {
      FB = Object('FB');
    }

    var _showFbContainer = function() {
      $('.social-login__container').removeClass('hidden');
      // ACAC-5100, Runs AFTER fb button iframe loads so <Footer> is adjusted properly by doAdjustments() in themes/cl_base/js/pc/pc-main.js
      !site.client.isMobile && document.querySelector('body#signin #content .social-login') && $(window).trigger('resize');
    };

    var _getAppId = function() {
      if (settings.fb_login_app_id) {
        return settings.fb_login_app_id;
      }
    };

    $('body').on('click', '.js-facebook_disclaimer', function () {
      // DOM changes were made by ajax
      if ($(this).is(':checked')) {
        $('.js-fb-button-overlay, .js-fb-disclaimer-error').addClass('hidden');
      } else {
        $('.js-fb-button-overlay, .js-fb-disclaimer-error').removeClass('hidden');
      }
      $('.js-facebook_disclaimer').prop('checked', $(this).is(':checked'));
    });

    $('body').on('click', '.js-fb-button-overlay', function () {
      $('.js-fb-disclaimer-error').removeClass('hidden');
    });

    var _handleLoginStatusResponse = function(response) {
      //console.log("Facebook status in _handleLoginStatusResponse: " + response.status);
      // If we're on the signout page (for any reason - timeout or logout),
      // log the user out according to Facebook
      if (location.pathname.match('/account/signin.tmpl') && location.search.match('_SUBMIT=signout') && (response.status === 'connected')) {
        // FB.logout likes to not work. So, we call this recursively until it does.
        FB.logout(_handleLogoutSessionResponse);
      }
    };

    var _handleLogoutSessionResponse = function(response) {
      //console.log("Facebook status in _handleLogoutSessionResponse: " + response.status);
      // If we're connected, disconnect, again recursively because Facebook is clingy.
      if (response.status === 'connected') {
        FB.logout(_handleLogoutSessionResponse);
      }
    };

    var _init = function() {
      var accountSigninRedirect = settings.federal_id_check || false;

      _getDOMNodes();

      // Make sure that the form action points to the SSL version otherwise it fails to log in
      var action_url = '/account/signin.tmpl',
          checkOutPaths = [
            '/checkout/checkout.tmpl',
            '/checkout/signin.tmpl',
            '/checkout/logginn.tmpl',
            '/checkout/loggain.tmpl',
            '/checkout/login.tmpl'
          ];

      if (!accountSigninRedirect && (location.pathname.match('/checkout/index.tmpl') || location.pathname.match('/checkout/samples.tmpl'))) {
        action_url = '/checkout/index.tmpl';
      }

      // Non-single page checkout setting
      if (checkOutPaths.some(function(path){return location.pathname.match(path)})) {
        action_url = '/checkout/checkout.tmpl';
      }

      nodes.form.attr('action', action_url);

      // Set the RETURN_URL to the currently viewed page
      // BB NA redirects to account/checkout index page
      // ACAC-2004 redirect to profile preferences page when clicked on order confirmation
      var returnUrlInput = $('<input>').attr({
        type: 'hidden',
        name: 'RETURN_URL'
      });
      var return_url = null, query_string = null;
      if (location.pathname.match('/checkout/confirm.tmpl')) {
        //var returnUrl = [location.protocol, '//', location.host, location.pathname].join('');
        return_url = '/account/profile_preferences.tmpl';
        returnUrlInput.val(return_url);
        returnUrlInput.appendTo(nodes.form);
      }
      // If URL contains RETURN_URL in query
      if (location.search.match('RETURN_URL')) {
        if (typeof URLSearchParams === 'function') {
          query_string = new URLSearchParams(location.search);
          return_url = query_string.get('RETURN_URL');
        } else {
          return_url = getUrlParameter('RETURN_URL');
        }
        if (typeof return_url !== 'undefined') {
          returnUrlInput.val(decodeURIComponent(return_url));
          returnUrlInput.appendTo(nodes.form);
        }
      } else if (location.search.match('bvdisplaycode')) {
        return_url = location.pathname + location.search;
        return_url = return_url.substr(1);
        if (typeof URLSearchParams === 'function') {
          query_string = new URLSearchParams(location.search);
          return_url = location.pathname + location.search;
          return_url = return_url.substr(1);
        }
        if (typeof return_url !== 'undefined') {
          returnUrlInput.val(decodeURIComponent(return_url));
          returnUrlInput.appendTo(nodes.form);
          nodes.form.attr('action', return_url);
        }
      }

      // Hook up any additional "opt in" input field on the page to the main
      // facebook_signin form's fields.
      if (nodes.optInInput !== 'undefined') {
        nodes.optInInput.on('click', function() {
          // Find the field used to opt the user into email promotions
          var $optInFormInput = nodes.form.find('#form--facebook_signin--field--PC_EMAIL_PROMOTIONS');

          // match the checkbox state
          $optInFormInput.prop('checked', $(this).is(':checked'));
          nodes.optInInput.prop('checked', $(this).is(':checked'));

          // and fill in the LAST_SOURCE field
          var source = 'Facebook_gnav';
          // (Note that /checkout/confirm.tmpl must come before /checkout/)
          if (location.pathname.match('/checkout/confirm.tmpl')) {
            source = 'Facebook_order_confirmation';
          } else if (location.pathname.match('/account/signin.tmpl') || location.pathname.match('/account/index.tmpl')) {
            source = 'Facebook_account_signup';
          } else if (location.pathname.match('/checkout/')) {
            source = 'Facebook_checkout_signin';
          }
          nodes.form.find('#form--facebook_signin--field--LAST_SOURCE').val(source);
        });
      }
      // When the user clicks "Disconnect" in her account, "disconnect"
      // her Facebook account (which, in Facebook terms, means "logout"),
      // *then* submit the facebook_disconnect form so that the back end can
      // clear her Facebook data from USER_LOGINS_TBL and USER_SOCIAL_INFO_TBL.
      if (nodes.fbDisconnect !== 'undefined') {
        nodes.fbDisconnect.on('click', function(event) {
          event.preventDefault(); // We'll post the form when we're done
          FB.logout(function(response) {
            // Make sure FB logged us out
            _handleLogoutSessionResponse(response);
            // Tell the back end to clear their account link
            nodes.fbDisconnect.trigger('submit');
          });
        });
      }

      $('.js-facebook-button').on('click', function(e) {
        e.preventDefault();
        FB.login(function(response) {
          if (response.status === 'connected') {
            site.facebook.continueWithFacebook();
          }
        }, { scope: 'public_profile,email' });
      });

      var appId = _getAppId();

      if (appId) {
        if (typeof FB === 'object') {
          _initFB(appId);
        }
      }
      if (typeof FB === 'object') {
        FB.getLoginStatus(function(response) {
          _handleLoginStatusResponse(response);
        });
      }
    };

    var _handleContinueWithFacebook = function(response) {
      if (response.status === 'connected') {
        // Extract authResponse.accessToken
        var token = response.authResponse.accessToken;
        nodes.tokenInput.val(token);
        $(document.body).append(nodes.form);
        nodes.form.trigger('submit');
      } else if (response.status === 'not_authorized') {
        // display error that app hasnt been authorized
        generic.showErrors([{text: site.facebook.not_authorized}], nodes.errorContainer);
      } else if (response.status === 'unknown') {
        // Do nothing - login didn't happen (usually means they clicked Cancel)
        // If we were tightly coupled with the site's login state, we'd log
        // the user out. But we interpret this more as "disconnected from
        // Facebook", so we do nothing.
        //console.log("_handleContinueWithFacebook called with response.status === 'unknown'");
      } else {
        generic.showErrors([{text: site.facebook.login_error}], nodes.errorContainer);
      }
    };

    /* Fallback function if URLSearchParams is not available
     * Returning get parameter value from the current URL
     */
    var getUrlParameter = function(name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    return {
      init: function() {
        _init();
      },
      continueWithFacebook: function() {
        var facebookDisclaimer = $('input.js-facebook_disclaimer');
        if (facebookDisclaimer.length && !facebookDisclaimer.is(':checked')) {
          $('.js-fb-disclaimer-error').removeClass('hidden');
          return false;
        }
        FB.getLoginStatus(function(response) {
          _handleContinueWithFacebook(response);
        });
      }
    };
  }());
  window.fbAsyncInit = function() {
    site.facebook.init();
  };
})(jQuery);
